@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;

/* CSS Document */
/*====================================================================

	scroll animation

=====================================================================*/
/* fade-in */
.fade-in {
  &.fade-in-trigger--off,
  .fade-in-trigger--off & {
    opacity: 0;
  }
  &.fade-in-trigger--on,
  .fade-in-trigger--on & {
    transition: all 0.8s cubic-bezier(0.34, 0.09, 0.98, 0.83);
    opacity: 1;
  }
}

/* fade-up */
.fade-up{
  &.fade-up-trigger--off,
  .fade-up-trigger--off &{
    opacity: 0;
    transform: translateY(100px);
  }
  &.fade-up-trigger--on,
  .fade-up-trigger--on &{
    transition: all .8s cubic-bezier(.38,.1,.72,.96);
    opacity: 1;
    transform: translateY(0);
  }
}
